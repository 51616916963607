
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from "@ionic/vue";
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default defineComponent({
  name: "Failure",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    Header,
    Footer,
  },
  methods: {
    getMessage() {
      return (
        this.$route.query.errormessage ||
        "There has been an error, please contact support."
      );
    },
  },
});
